<template>
  <div class="equipment">
    <!-- 导航 -->
    <div class="equipment-nav">
      <!-- <div class="eq-theme">{{ name }}</div> -->
      <div
        class="eq-tit"
        :class="{ activeClass: currentIndex === index }"
        @click="changeActive(index, item)"
        v-for="(item, index) in equipmentList"
        :key="index"
      >
        <!-- <i class="el-icon-arrow-right"></i> -->
        {{ item.name }}
      </div>
    </div>
    <!-- 设备 -->
    <div
      class="equipment-introduce"
      v-if="equipmentContent && equipmentContent.pageDatas.length > 0"
    >
      <el-image
        style="min-width: 500px; height: 100%"
        :src="equipmentContent.pageDatas[0].img"
        fit="cover"
      ></el-image>
      <div class="ei-word">
        <h5 class="ei-theme">{{ equipmentContent.name }}</h5>
        <i></i>
        <div class="ei-text">{{ equipmentContent.pageDatas[0].jsonText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  name: "",
  data() {
    return {
      name: "",
      equipmentList: [],
      equipmentContent: null,
      currentIndex: 0,
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("http://edu.siwonet.com:8099/queryTitleId", {
          params: {
            language: lang,
            titleId: this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.name = res.data.data.name;
            if (res.data.data.titles) {
              this.equipmentList = res.data.data.titles;
              this.equipmentContent = this.equipmentList[0];
            }
          } else {
            this.$message.error(data.msg);
          }
        });
    },
    changeActive(index, item) {
      this.equipmentContent = item;
      this.currentIndex = index;
    },
  },
  computed: {},
  filters: {},
};
</script>

<style lang="less" scoped>
.equipment {
  // width: 100%;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  .equipment-nav {
    width: 250px;
    margin-right: 50px;
    .eq-theme {
      color: rgb(255, 255, 255);
    }
    .eq-theme,
    .eq-tit {
      height: 50px;
      // background-color: #d80930;
      // color: rgb(255, 255, 255);
      line-height: 50px;
      font-size: 18px;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .eq-tit {
      cursor: pointer;
      font-size: 15px;
      // background-color: #f2f2f2;
      // color: #4d4d4d;
      // border-bottom: 1px solid #ffffff;
      border-bottom: 1px solid rgba(226, 9, 20, 0.8);
    }
    .activeClass {
      // background-color: #dfdfdf;
    }
  }
  .equipment-introduce {
    min-height: 300px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #f2f2f2;
    .ei-word {
      padding: 50px;
      box-sizing: border-box;
      .ei-theme {
        font-size: 20px;
        color: #090909;
        font-weight: normal;
      }
      i {
        display: block;
        width: 18px;
        height: 4px;
        background: #d80930;
        margin-top: 16px;
        margin-bottom: 18px;
      }
      .ei-text {
        font-size: 15px;
        line-height: 36px;
        color: #4d4d4d;
      }
    }
  }
}
</style>
